// states: :focus, onError:true|false

.TextInput_label {
    &[base] {
        // Appearance
        font-size: var(--text-normal);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Miscellaneous
        cursor: pointer;
        user-select: none;

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.TextInput_field {
    &[base] {
        /* Appearance */
        background-color: var(--white);
        border-radius: var(--radius-sm);
        border-style: solid;
        border-color: var(--gray-400);

        /* Miscellaneous */
        transition: all var(--duration-100) var(--ease-in);
        -webkit-appearance: none;
        appearance: none;
        resize: vertical;

        /* Inner layout */
        border-width: var(--thickness-sm);
        padding: var(--space-3) var(--space-4);

        &:focus {
            /* Appearance */
            border-color: var(--indigo-500);
            box-shadow: 0 0 0 var(--thickness-sm) var(--indigo-500);
        }

        &.§onError\:true {
            /* Appearance */
            background-color: var(--red-100);
            border-color: var(--red-700);
            box-shadow: none;

            /* Inner layout */
            border-width: var(--thickness-sm);
        }

        &.§onError\:true:focus {
            /* Appearance */
            border-color: var(--red-700);
            box-shadow: 0 0 0 var(--thickness-sm) var(--red-700);
        }
    }
}

// text
.TextInput_field {
    &[base] {
        /* Appearance */
        font-size: var(--text-lg);
        font-weight: var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        /* Inner layout */
        line-height: var(--leading-normal);
    }
}

// placeholder
.TextInput_field[base]::placeholder {
    /* Appearance */
    color: var(--gray-500);

    // Miscellaneous
    user-select: none;
}

.TextInput_errorMessage {
    &[base] {
        // Appearance
        font-size: var(--text-sm);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--red-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}
