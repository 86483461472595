// states: §visible:false|true

.MetricCard_root {
    &[base] {
        // Style props
        --headerBackgroundColor: var(--gray-600);

        // Appearance
        overflow: hidden;
        box-shadow: var(--shadow-lg-improved);
        border-radius: var(--radius-lg);
        background-color: var(--white);

        // Miscellaneous
        transition: all var(--ease-in-out) var(--duration-500);

        &.§visible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§visible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }
    }
}

.MetricCard_header {
    &[base] {
        // Appearance
        background-color: var(--headerBackgroundColor);

        // Inner layout
        padding: var(--space-3) var(--space-4);
    }
}

.MetricCard_title {
    &[base] {
        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--white);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.MetricCard_content {
    &[base] {
        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-300);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
    }
}

.MetricCard_contentList {
    &[base] {
        // Inner layout
        padding: var(--space-3) var(--space-4);
        display: grid;
        gap: var(--space-3);
    }
}

.MetricCard_contentItem {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.subscript {
    .MetricCard_contentItem[base] > & {
        // Own layout
        vertical-align: sub;
    }
}

.MetricCard_contentItem[base]::before {
    content: "";

    // Own layout
    display: inline-block;
    margin-right: var(--space-2);
    margin-bottom: 3px;
    width: 4px;
    height: 4px;

    // Appearance
    border-radius: var(--radius-full);
    background-color: var(--gray-400);
}

.MetricCard_coverage {
    &[base] {
        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-300);
        background-color: var(--gray-100);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
        padding: var(--space-3) var(--space-4);
        display: grid;
        gap: var(--space-2);
    }
}

.MetricCard_coverageTitle {
    &[base] {
        // Appearance
        font-size: var(--text-xs);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.MetricCard_coverageContent {
    &[base] {
        // Own layout
        margin-right: calc(-1 * var(--space-2));
        margin-bottom: calc(-1 * var(--space-2));

        // Inner layout
        display: flex;
        flex-wrap: wrap;
    }
}

.MetricCard_coverageItem {
    &[base] {
        // Own layout
        margin-right: var(--space-2);
        margin-bottom: var(--space-2);
        flex-shrink: 0;

        // Appearance
        border-style: solid;
        border-color: var(--gray-500);
        border-radius: var(--radius-full);
        background-color: var(--gray-500);

        // Inner layout
        border-width: var(--thickness-sm);
        padding: 0 var(--space-2);
    }
}

// text
.MetricCard_coverageItem {
    &[base] {
        // Appearance
        font-size: var(--text-xs);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-100);

        // Miscellaneous
        user-select: none;
        cursor: default;

        // Inner layout
        line-height: var(--leading-normal);
    }
}
