// variations: _overContent
// states: @media ($bp1_true|$bp1_false), §open:true|false, §topOfPage:true|false

$bp1_true: "min-width: 860px";
$bp1_false: "max-width: 859px";

.Menu_root {
    &[base] {
        // Own layout
        position: fixed;
        top: 0;
        z-index: 10;
        width: 100%;

        // Miscellaneous
        transition: all var(--ease-in) var(--duration-100);

        @media ($bp1_true) {
            // Appearance
            background-color: var(--white);
            box-shadow: var(--shadow-lg);

            // Inner layout
            padding: 0 var(--space-6);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &._overContent {
        // prettier-ignore
        @media ($bp1_true) { &.§topOfPage\:true {
            // Appearance
            background-color: transparent;
            box-shadow: none;
        }}
    }
}

.Menu_overlay {
    &[base] {
        // Own layout
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        // Appearance
        opacity: 0;
        background-color: var(--gray-900);

        // Miscellaneous
        transition: all var(--ease-in) var(--duration-100);
        pointer-events: none;

        &.§open\:true {
            // Appearance
            opacity: 0.5;

            // Miscellaneous
            pointer-events: initial;
        }
    }
}

.Menu_header {
    &[base] {
        // Own layout
        position: relative;
        height: var(--space-10);

        // Appearance
        background-color: var(--white);
        box-shadow: var(--shadow-lg);

        // Miscellaneous
        transition: all var(--ease-in) var(--duration-100);

        // Inner layout
        padding: 0 var(--space-3);
        display: grid;
        grid-template-columns: 52px 1fr 52px;
        gap: var(--space-3);
        align-items: center;
        justify-items: center;

        @media ($bp1_true) {
            // Appearance
            background-color: transparent;
            box-shadow: none;

            // Inner layout
            padding: 0;
            display: flex;
        }
    }

    &._overContent {
        &.§topOfPage\:true.§open\:false {
            // Appearance
            background-color: transparent;
            box-shadow: none;
        }
    }
}

a.\#logo {
    .Menu_header[base] > & {
        // Own layout
        grid-column: 2;

        // Appearance
        border-style: solid;
        border-color: transparent;
        font-size: var(--text-3xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--gray-800);

        // Miscellaneous
        user-select: none;
        cursor: pointer;

        // Inner layout
        border-top-width: var(--thickness-md);
        border-bottom-width: var(--thickness-md);
        text-align: center;
        line-height: var(--leading-normal);

        &:focus {
            // Appearance
            border-bottom-color: var(--gray-800);
        }
    }

    .Menu_header._overContent.§open\:false.§topOfPage\:true > & {
        // Appearance
        color: var(--white);

        &:focus {
            // Appearance
            border-bottom-color: var(--white);
        }
    }
}

.Menu_logoBy {
    &[base] {
        // Own layout
        display: none;

        // Appearance
        opacity: 0.75;
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        text-transform: initial;
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_true) {
            // Own layout
            display: inline;
        }

        &._overContent {
            &.§open\:false.§topOfPage\:true {
                // Appearance
                color: var(--white);
            }
        }
    }
}

.MenuButton_root {
    .Menu_header[base] > & {
        // Own layout
        grid-column: 3;

        @media ($bp1_true) {
            // Own layout
            display: none;
        }
    }
}

.Menu_items {
    &[base] {
        // Own layout
        position: absolute;
        top: var(--space-10);
        width: 100%;

        // Appearance
        border-style: solid;
        border-color: transparent;
        border-radius: 0 0 var(--radius-lg) var(--radius-lg);
        background-color: var(--white);
        box-shadow: var(--shadow-lg);

        // Miscellaneous
        transition: all var(--ease-in) var(--duration-100);

        // Inner layout
        border-top-width: var(--thickness-sm);
        padding: var(--space-6);
        display: grid;
        gap: var(--space-6);
        justify-items: center;

        &.§open\:false {
            // Appearance
            opacity: 0;
            pointer-events: none;
        }

        &.§open\:true {
            // Appearance
            border-color: var(--gray-300);
        }

        @media ($bp1_true) {
            // Own layout
            position: static;
            width: auto;

            // Appearance
            background-color: transparent;
            box-shadow: none;

            // Inner layout
            border-top-width: 0;
            padding: 0;
            grid-auto-flow: column;
            gap: var(--space-8);
            align-items: center;

            &.§open\:false {
                // Appearance
                opacity: 1;
                pointer-events: initial;

                // Inner layout
                display: grid;
            }
        }
    }
}

.Menu_item.\#mobileButton[base] {
    // Own layout
    justify-self: center;

    @media ($bp1_true) {
        // Own layout
        display: none;
    }
}

.Menu_item.\#desktopButton[base] {
    // Own layout
    display: none;

    @media ($bp1_true) {
        // Own layout
        display: flex;
    }
}
