// variations: _even:true|false
// states: @media ($bp2_true|$bp2_false), §visible:false|true

$bp1_true: "min-width: 600px";
$bp1_false: "max-width: 599px";

.Feature_root {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-3);
        justify-items: start;

        // Miscellaneous
        transition: all var(--ease-in-out) var(--duration-500);

        &.§visible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§visible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }

        @media ($bp1_true) {
            // Inner layout
            grid-template-columns: auto 1fr;
            gap: var(--space-4);
            align-items: start;
        }
    }
}

.Feature_iconWrapper {
    &[base] {
        // Appearance
        border-radius: var(--radius-full);
        background-color: var(--indigo-500);

        // Inner layout
        padding: var(--space-4);

        @media ($bp1_true) {
            // Inner layout
            padding: var(--space-5);
        }
    }
}

.Icon_root {
    .Feature_iconWrapper[base] > & {
        // Style props
        --stroke-color: var(--white);
    }
}

.Feature_textWrapper {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-1);
    }
}

.Feature_title {
    &[base] {
        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-semibold);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-snug);
    }
}

.Feature_content {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
    }
}
