// states: focus, open:true|false, overContent:true|false

.MenuButton_root {
    &[base] {
        // Own layout
        width: 52px;
        height: 52px;

        // Miscellaneous
        cursor: pointer;

        &:focus {
            // Appearance
            border-radius: var(--radius-lg);
            box-shadow: inset 0 0 0 var(--thickness-md) var(--white);
        }

        &.§overContent\:false:focus {
            box-shadow: inset 0 0 0 var(--thickness-md) var(--gray-800);
        }

        &.§open\:true:focus {
            // Appearance
            box-shadow: inset 0 0 0 var(--thickness-md) var(--gray-800);
        }
    }
}

.Icon_root {
    .MenuButton_root[base] > & {
        // Style props
        --stroke-color: var(--gray-800);
    }

    .MenuButton_root.§overContent\:true > & {
        // Style props
        --stroke-color: var(--white);
    }
}
