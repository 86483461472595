// states: @media ($bp1_true|$bp1_false), :hover, :focus, §active:true|false, §:menuOpen:true|false, §menuTopOfPage:true|false

$bp1_true: "min-width: 860px";
$bp1_false: "max-width: 859px";

.MenuItem_root {
    &[base] {
        // Appearance
        border-style: solid;
        border-color: transparent;
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        border-top-width: var(--thickness-md);
        border-bottom-width: var(--thickness-md);
        line-height: var(--leading-normal);
        text-align: center;

        // Miscellaneous
        user-select: none;
        transition: all var(--ease-in) var(--duration-100);

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-base);
        }

        &:hover {
            // Appearance
            color: var(--gray-900);
        }

        &:focus {
            // Appearance
            border-bottom-color: var(--gray-700);
        }

        &:focus.§menuOpen\:false.§menuTopOfPage\:true {
            @media ($bp1_true) {
                // Appearance
                border-bottom-color: var(--white);
            }
        }

        &.§active\:true {
            // Appearance
            font-variation-settings: "wght" var(--font-weight-bold);
        }

        &.§menuOpen\:false.§menuTopOfPage\:true {
            // Appearance
            color: var(--white);
        }

        &:hover.§menuOpen\:false.§menuTopOfPage\:true {
            // Appearance
            opacity: 0.8;
        }
    }
}
