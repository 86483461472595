// states: @media ($bp1_true|$bp1_false)

$bp1_true: "min-width: 760px";
$bp1_false: "max-width: 759px";

.Footer_root {
    &[base] {
        // Appearance
        border-color: var(--gray-300);
        border-style: solid;
        background-color: var(--gray-200);

        // Inner layout
        border-top-width: 1px;
        padding: var(--space-9) 0 var(--space-6);
        display: grid;
        // prettier-ignore
        grid-template-columns: minmax(24px, 1fr) minmax(0, 976px) minmax(24px, 1fr);
    }
}

.Footer_wrapper {
    &[base] {
        // Own layout
        grid-column: 2;

        // Inner layout
        display: grid;
        align-items: start;
        gap: var(--space-7);

        @media ($bp1_true) {
            // Inner layout
            grid-template-columns: 1fr 1fr 1fr;
            gap: var(--space-7) var(--space-13);
        }
    }
}

.Footer_section {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-2);
    }
}

.Footer_section.\#contact {
    &[base] {
        // Inner layout
        gap: var(--space-3);
    }
}

.Footer_sectionTitle {
    &[base] {
        // Appearance
        font-size: var(--text-sm);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--gray-500);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-xs);
        }
    }
}

.Footer_links {
    &[base] {
        // Inner layout
        display: grid;
        gap: 8px;

        @media ($bp1_true) {
            // Inner layout
            gap: 4px;
        }
    }
}

.Footer_CustomLink {
    &[base] {
        // Own layout
        display: block;
        width: 100%;

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-base);
        }
    }
}

.Footer_companyDescription {
    &[base] {
        // Own layout
        max-width: 332px;

        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-base);
        }
    }
}

.Footer_social {
    &[base] {
        // Inner layout
        display: grid;
        width: min-content;
        grid-auto-flow: column;
        gap: var(--space-3);
    }
}

.Footer_address {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-sm);
        }
    }
}

.Footer_companyName {
    &[base] {
        // Own layout
        display: block;

        // Appearance
        font-size: var(--text-lg);

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-base);
        }
    }
}

.Footer_copyright {
    &[base] {
        // Appearance
        border-color: var(--gray-300);
        border-style: solid;

        // Inner layout
        border-top-width: var(--thickness-sm);
        padding-top: var(--space-3);

        @media ($bp1_true) {
            // Own layout
            grid-column: 1 / -1;
        }
    }
}

// text
.Footer_copyright {
    &[base] {
        // Appearance
        font-size: var(--text-sm);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-500);

        // Inner layout
        line-height: var(--leading-normal);
    }
}
