// variations: _inClipped
// states: §selected:true|false, :focus

.Tab_button {
    &[base] {
        // Appearance
        border-radius: var(--radius-full);

        // Miscellaneous
        cursor: pointer;
        user-select: none;
        transition: all var(--ease-in) var(--duration-100);

        // Inner layout
        padding: var(--space-1) var(--space-4);

        &:focus {
            // Appearance
            box-shadow: 0 0 0 var(--thickness-md) var(--gray-100),
                0 0 0 var(--thickness-lg) var(--gray-700);
        }
    }
}

// text
.Tab_button {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }

    &._inClipped {
        // Appearance
        color: var(--gray-100);
    }
}
