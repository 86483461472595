// Prevents all transition on media queries change
* {
    .App_root[base].§resizing\:true & {
        transition: none !important;
    }
}

// Set the selection color throughout the website
*::selection {
    .App_root[base] & {
        // Appearance
        background-color: rgba(127, 156, 245, 0.4); // --indigo-400, 40% opacity
    }
}

.Footer_root {
    .App_root[base] > & {
        // Own layout
        position: relative;
        z-index: 1;
    }
}
