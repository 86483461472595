// states: @media ($bp1_true|$bp1_false)

$bp1_true: "min-width: 900px";
$bp1_false: "max-width: 899px";

.MetricsSection_root {
    &[base] {
        --menuHeight: 64px;
        --visibleSectionHeight: calc(100vh - var(--menuHeight));
    }
}

.MetricsSection_visibleSection {
    &[base] {
        // Appearance
        background-color: var(--gray-100);

        // Inner layout
        position: relative;

        @media ($bp1_false) {
            // Inner layout
            padding: var(--space-10) 0;
        }

        @media ($bp1_true) {
            // Own layout
            position: sticky;
            top: var(--menuHeight);
            height: var(--visibleSectionHeight);

            // Inner layout
            display: grid;
            // prettier-ignore
            grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
        }
    }
}

.DotsPattern_root {
    .MetricsSection_visibleSection[base] > & {
        // Own layout
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        width: 320px;
        max-width: 80vw;
        height: 320px;

        @media ($bp1_true) {
            // Own layout
            max-width: 92vw;
        }
    }
}

.DotsPattern_root::before {
    .MetricsSection_visibleSection[base] > & {
        // Own layout
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        // Appearance
        background: linear-gradient(
            225deg,
            rgba(247, 250, 252, 0.4) 0%,
            rgba(247, 250, 252, 0.66) 50%,
            rgba(247, 250, 252, 1) 100%
        );

        @media ($bp1_true) {
            background: linear-gradient(
                190deg,
                rgba(247, 250, 252, 0.4) 0%,
                rgba(247, 250, 252, 0.4) 33%,
                rgba(247, 250, 252, 0.6) 50%,
                rgba(247, 250, 252, 1) 100%
            );
        }
    }
}

.MetricsSection_visibleSectionInnerGrid {
    &[base] {
        // Own layout
        position: relative;

        @media ($bp1_true) {
            // Own layout
            grid-column: 2;
            grid-row: 1 / -1;

            // Inner layout
            display: grid;
            // prettier-ignore
            grid-template-columns: 1fr 1fr;
            gap: var(--space-7);
            align-items: center;
        }
    }
}

.MetricsSection_textualContent {
    &[base] {
        // Own layout
        grid-column: 2;

        // Inner layout
        display: grid;
        justify-items: start;

        @media ($bp1_false) {
            // Own layout
            // prettier-ignore
            grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
            gap: var(--space-6) 0;
        }

        @media ($bp1_true) {
            gap: var(--space-6);
        }
    }
}

.MetricsSection_supTitle {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--indigo-600);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_false) {
            // Own layout
            grid-column: 2;
        }
    }
}

.MetricsSection_title {
    &[base] {
        // Own layout
        max-width: 640px;

        // Appearance
        font-size: var(--text-4xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-snug);

        @media ($bp1_false) {
            // Own layout
            grid-column: 2;
        }
    }
}

.MetricsSection_content {
    &[base] {
        // Inner layout
        display: grid;

        @media ($bp1_false) {
            // Own layout
            grid-column: 1 / -1;

            // Inner layout
            // prettier-ignore
            grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
            gap: var(--space-8) 0;
        }

        @media ($bp1_true) {
            // Inner layout
            gap: var(--space-4);
        }
    }
}

.MetricsSection_paragraph {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);

        @media ($bp1_false) {
            // Own layout
            grid-column: 2;
        }
    }
}

.MetricsSection_metrics.\#mobile {
    &[base] {
        @media ($bp1_false) {
            // Style props
            --globalMargin: var(--space-6);
            --visibleGroupsGap: var(--space-4);
            --visibleGroupPadding: var(--space-4);
            --visibleGroupHeaderHeight: 62px;
            --itemsGap: var(--space-6);
            --itemMaxWidth: 320px;

            // Own layout
            grid-column: 1 / -1;

            // Miscellaneous
            overflow-x: auto;
            overscroll-behavior: contain auto;
            scroll-snap-type: x mandatory;
            scroll-padding-left: calc(
                var(--globalMargin) + var(--visibleGroupPadding) +
                    var(--thickness-sm) * 1
            );
            scroll-padding-right: calc(
                var(--globalMargin) + var(--visibleGroupPadding) +
                    var(--thickness-sm) * 1
            );
            scrollbar-width: none; // For Firefox

            // Inner layout
            display: grid;
            grid-auto-flow: column;
            gap: var(--visibleGroupsGap);
            align-items: start;
        }

        @media ($bp1_true) {
            // Own layout
            display: none;
        }
    }
}

// For Chromium/Webkit-based browsers
.MetricsSection_metrics.\#mobile[base]::-webkit-scrollbar {
    // Own layout
    display: none;
}

.MetricsSection_metrics[base].\#mobile::before,
.MetricsSection_metrics[base].\#mobile::after {
    @media ($bp1_false) {
        content: "";
        width: calc(var(--globalMargin) - var(--visibleGroupsGap));
        height: 100%;
    }
}

.MetricsSection_cardGroupWrapper {
    &[base] {
        @media ($bp1_false) {
            // Inner layout
            display: grid;
            grid-auto-flow: column;
            gap: var(--itemsGap);
            align-items: start;
        }
    }
}

.MetricsSection_cardGroupWrapper.\#climate {
    &[base] {
        @media ($bp1_false) {
            // Style props
            --childrenCount: 3;
        }
    }
}

.MetricsSection_cardGroupWrapper.\#business {
    &[base] {
        @media ($bp1_false) {
            // Style props
            --childrenCount: 2;
        }
    }
}

.MetricCard_root {
    .MetricsSection_cardGroupWrapper[base] > & {
        @media ($bp1_false) {
            // Own layout
            grid-row: 1;
            position: relative;
            margin-top: calc(
                var(--thickness-sm) * 2 + var(--visibleGroupHeaderHeight) +
                    var(--visibleGroupPadding)
            );
            margin-bottom: calc(
                var(--visibleGroupPadding) + var(--thickness-sm)
            );
            width: calc(
                100vw - 2 *
                    (var(--space-6) + var(--space-4) + var(--thickness-sm))
            );
            max-width: var(--itemMaxWidth);

            // Miscellaneous
            scroll-snap-align: center;
        }

        // prettier-ignore
        @media ($bp1_false) { &:nth-child(2) {
            // Own layout
            grid-column: 1;
            margin-left: calc(var(--visibleGroupPadding) + var(--thickness-sm));

            // Miscellaneous
            scroll-snap-align: start;
        }}

        // prettier-ignore
        @media ($bp1_false) { &:last-child {
            // Own layout
            grid-column: var(--childrenCount);
            margin-right: calc(var(--visibleGroupPadding) + var(--thickness-sm));

            // Miscellaneous
            scroll-snap-align: end;
        }}
    }
}

.MetricCard_root.\#climate {
    .MetricsSection_cardGroupWrapper[base] > & {
        @media ($bp1_false) {
            // Style props
            --headerBackgroundColor: var(--green-300);
        }
    }
}

.MetricCard_root.\#business {
    .MetricsSection_cardGroupWrapper[base] > & {
        @media ($bp1_false) {
            // Style props
            --headerBackgroundColor: var(--teal-300);
        }
    }
}

.MetricCard_root.\#climate.\#2 {
    .MetricsSection_cardGroupWrapper[base] > & {
        @media ($bp1_false) {
            grid-column: 2;
        }
    }
}

.Button_root {
    .MetricsSection_textualContent[base] > & {
        @media ($bp1_false) {
            // Own layout
            grid-column: 2;
        }
    }
}

.MetricsSection_metrics.\#desktop {
    &[base] {
        // Own layout
        display: none;

        @media ($bp1_true) {
            // Own layout
            position: relative;
            margin-top: calc(
                -1 * (var(--visibleSectionHeight) - var(--space-12))
            );
            padding-bottom: var(--space-12);

            // Miscellaneous
            pointer-events: none;

            // Inner layout
            display: grid;
            // prettier-ignore
            grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
        }
    }
}

.MetricsSection_metricsInnerGrid {
    &[base] {
        // Own layout
        grid-column: 2;

        // Inner layout
        display: grid;
        // prettier-ignore
        grid-template-columns: 1fr 1fr;
        gap: var(--space-7);
    }
}

.MetricsSection_cardGroups {
    &[base] {
        // Miscellaneous
        pointer-events: initial;

        // Inner layout
        display: grid;
        gap: var(--space-8);
    }
}

.MetricsSection_cardGroup {
    &[base] {
        // Appearance
        border-radius: var(--radius-lg);
        border-style: solid;
        border-color: var(--gray-400);

        // Inner layout
        border-width: var(--thickness-sm);

        @media ($bp1_false) {
            // Own layout
            grid-column: 1 / calc(var(--childrenCount) + 1);
            grid-row: 1;
            position: sticky;
            left: var(--globalMargin);
            box-sizing: border-box;
            width: calc(100vw - 2 * var(--globalMargin));
            max-width: calc(
                var(--childrenCount) * var(--itemMaxWidth) +
                    (var(--childrenCount) - 1) * var(--itemsGap) + 2 *
                    var(--visibleGroupPadding)
            );
            height: 100%;
        }

        @media ($bp1_true) {
            // Own layout
            max-width: 400px;
        }
    }
}

.MetricsSection_cardGroup.\#climate {
    &[base] {
        @media ($bp1_false) {
            // Style props
            --childrenCount: 3;
        }
    }
}

.MetricsSection_cardGroup.\#business {
    &[base] {
        @media ($bp1_false) {
            // Style props
            --childrenCount: 2;
        }
    }
}

.MetricsSection_cardGroupHeader {
    &[base] {
        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-400);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
        padding: var(--space-4) 0;
    }
}

// text
.MetricsSection_cardGroupHeader {
    &[base] {
        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.MetricsSection_cards {
    &[base] {
        // Inner layout
        padding: var(--space-7);
        display: grid;
        gap: var(--space-7);
    }
}

.MetricCard_root.\#climate {
    .MetricsSection_cards[base] > & {
        @media ($bp1_true) {
            // Style props
            --headerBackgroundColor: var(--green-300);
        }
    }
}

.MetricCard_root.\#business {
    .MetricsSection_cards[base] > & {
        @media ($bp1_true) {
            // Style props
            --headerBackgroundColor: var(--teal-300);
        }
    }
}
