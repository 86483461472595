// states: @media ($bp1_true|$bp1_false)

$bp1_true: "min-width: 760px";
$bp1_false: "max-width: 759px";

.Home_header {
    &[base] {
        // Own layout
        height: 100vh;

        // Miscellaneous
        overflow: hidden;

        // Inner layout
        position: relative;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
    }
}

.Home_overlay {
    &[base] {
        // Own layout
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;

        // Appearance
        background-color: var(--gray-900);
        opacity: 0;

        // Miscellaneous
        pointer-events: none;
    }
}

.Home_imageWrapper {
    &[base] {
        // Own layout
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        height: 100%;
    }
}

.Home_image {
    &[base] {
        // Own layout
        position: fixed;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100vh;

        // Miscellaneous
        user-select: none;
        will-change: transform;

        // Appearance
        object-fit: cover;
        object-position: top;
        filter: brightness(0.75);
    }
}

.Home_content {
    &[base] {
        // Own layout
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        margin: 0 var(--space-6);
        z-index: 1;

        // Inner layout
        display: grid;
        justify-items: center;
        gap: var(--space-7);

        @media ($bp1_true) {
            // Inner layout
            gap: var(--space-9);
        }
    }
}

.Home_titleContainer {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-4);

        @media ($bp1_true) {
            gap: var(--space-4);
        }
    }
}

.Home_title {
    &[base] {
        // Appearance
        font-size: var(--text-5xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--white);

        // Miscellaneous
        user-select: none;
        cursor: default;

        // Inner layout
        line-height: 0.75;
        text-align: center;

        @media ($bp1_true) {
            // Appearance
            font-size: 4.5rem;
        }
    }
}

.Home_legend {
    &[base] {
        // Appearance
        font-size: var(--text-sm);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--white);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.Home_subTitle {
    &[base] {
        // Own layout
        max-width: 750px;

        // Appearance
        font-size: var(--text-2xl);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--white);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;

        @media ($bp1_true) {
            // Appearance
            font-size: var(--text-4xl);
        }
    }
}

.Home_bottomBar {
    &[base] {
        // Own layout
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: stretch;
        align-self: end;
        z-index: 1;

        // Inner layout
        padding: var(--space-6);
        display: grid;
        justify-items: center;
        align-items: end;
    }
}

.Icon_root {
    .Home_bottomBar[base] > & {
        // Style props
        --stroke-color: var(--white);
    }
}

.Home_main {
    &[base] {
        // Own layout
        position: relative;
        z-index: 1;

        // Appearance
        box-shadow: var(--shadow-lg-inverted);
        background-color: var(--white);
    }
}

.Home_section {
    &[base] {
        // Inner layout
        position: relative;
        display: grid;
        // prettier-ignore
        grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
        grid-template-rows: var(--space-10) auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            grid-template-rows: var(--space-12) auto var(--space-12);
        }
    }
}

.Home_section.\#solution {
    &[base] {
        // Inner layout
        grid-template-rows: var(--space-9) auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            grid-template-rows: var(--space-10) auto var(--space-12);
        }
    }
}

.Home_section.\#coverage {
    &[base] {
        // Inner layout
        grid-template-rows: var(--space-9) auto auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            grid-template-rows: var(--space-12) auto auto var(--space-12);
        }
    }
}

.Home_section.\#visualisation {
    &[base] {
        // Inner layout
        grid-template-rows: var(--space-10) auto auto auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            grid-template-rows: var(--space-12) auto auto auto var(--space-12);
        }
    }
}

.Home_section.\#contact {
    &[base] {
        // Inner layout
        grid-template-rows: var(--space-10) auto auto var(--space-10);
        justify-items: center;

        @media ($bp1_true) {
            // Inner layout
            grid-template-rows: var(--space-12) auto auto var(--space-12);
        }
    }
}

.DotsPattern_root {
    .Home_section[base] > & {
        // Own layout
        position: relative;
        z-index: -1;
    }
}

.DotsPattern_root::before {
    .Home_section[base] > & {
        content: "";

        // Own layout
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.DotsPattern_root.\#solution {
    .Home_section[base] > & {
        // Own layout
        grid-column: 3;
        grid-row: 1 / -1;
        align-self: end;
        margin-left: var(--space-8);
        height: 390px;
        max-width: 90vw;
    }
}

.DotsPattern_root.\#solution::before {
    .Home_section[base] > & {
        // Appearance
        background: linear-gradient(
            -67.5deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.4) 30%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}

.DotsPattern_root.\#coverage {
    .Home_section[base] > & {
        // Own layout
        grid-column: 1;
        grid-row: 1 / -1;
        align-self: start;
        margin-right: var(--space-8);
        height: 200px;
        width: 600px;
        max-width: 100vw;
    }
}

.DotsPattern_root.\#coverage::before {
    .Home_section[base] > & {
        // Appearance
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.5) 33%,
            rgba(255, 255, 255, 0.8) 66%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}

.DotsPattern_root.\#visualisation {
    .Home_section[base] > & {
        // Own layout
        grid-column: 1;
        grid-row: 1 / -1;
        align-self: end;
        margin-right: var(--space-8);
        height: 165px;
        width: 600px;
        max-width: 100vw;
    }
}

.DotsPattern_root.\#visualisation::before {
    .Home_section[base] > & {
        // Appearance
        background: linear-gradient(
            12deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.4) 33%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}

.Home_sectionContent {
    &[base] {
        // Own layout
        grid-column: 2;
        grid-row: 2;

        // Inner layout
        display: grid;
        gap: var(--space-8);

        @media ($bp1_true) {
            // Inner layout
            gap: var(--space-9);
        }
    }
}

.Home_sectionMainInformation {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-6);
    }
}

.Home_sectionMainInformation.\#coverage {
    &[base] {
        // Inner layout
        grid-column: 2;
        grid-row: 2;
    }
}

.Home_sectionMainInformation.\#visualisation {
    &[base] {
        // Inner layout
        grid-column: 2;
        grid-row: 2;
    }
}

.Home_sectionSupTitle {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        text-transform: uppercase;
        color: var(--indigo-600);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.Home_sectionSupTitle.\#center {
    &[base] {
        // Inner layout
        text-align: center;
    }
}

.Home_sectionTitle {
    &[base] {
        // Own layout
        max-width: 640px;

        // Appearance
        font-size: var(--text-4xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-snug);
    }
}

.Home_sectionTitle.\#center {
    &[base] {
        // Own layout
        justify-self: center;

        // Inner layout
        text-align: center;
    }
}

.Home_sectionParagraph {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.Home_sectionParagraph.\#center {
    &[base] {
        // Inner layout
        text-align: center;
    }
}

.Home_sectionParagraph.\#contact {
    &[base] {
        // Own layout
        max-width: 640px;
    }
}

.Home_features {
    &[base] {
        // Inner layout
        display: grid;
        // prettier-ignore
        grid-template-columns: repeat(auto-fill, minmax(calc(min(392px, 100%)), 1fr));
        gap: var(--space-7);
        align-items: start;
    }
}

.Home_categoriesWrapper {
    &[base] {
        // Own layout
        grid-column: 1 / -1;
        grid-row: 3;
        margin-top: var(--space-9);
        // Necessary to prevent scroll when children of .Home_categories are translated
        // This removes the space added by the padding
        margin-bottom: calc(-1 * var(--space-3));

        // Miscellaneous
        overflow-x: auto;
        overscroll-behavior: contain auto;
        scroll-snap-type: x mandatory;
        scrollbar-width: none; // For Firefox

        // Inner layout
        display: grid;
        grid-auto-flow: column;

        @media ($bp1_true) {
            // Own layout
            grid-column: 2 / 3;
        }
    }
}

// For Chromium/Webkit-based browsers
.Home_categoriesWrapper[base]::-webkit-scrollbar {
    // Own layout
    display: none;
}

// Hack to have space before and after inside the scroll container
.Home_categoriesWrapper[base]::before,
.Home_categoriesWrapper[base]::after {
    content: "";
    width: var(--space-6);

    @media ($bp1_true) {
        // Own layout
        display: none;
    }
}

.Home_categories {
    &[base] {
        // Inner layout
        display: grid;
        grid-auto-flow: column;
        gap: var(--space-8);
        // Necessary to prevent scroll when children are translated
        // We add a padding so that they don't overflow and create
        // a scroll container
        padding-bottom: var(--space-3);

        @media ($bp1_true) {
            // Inner layout
            grid-auto-flow: initial;
            grid-template-columns: 1fr 1fr;
            gap: var(--space-10) var(--space-9);
            justify-items: center;
        }
    }
}

.CoverageCategory_root {
    .Home_categories[base] > & {
        // Own layout
        width: 75vw;
        max-width: 250px;

        // Miscellaneous
        scroll-snap-align: center;

        @media ($bp1_true) {
            // Own layout
            max-width: 280px;
        }
    }
}

.VisuSlider_tabNavWrapper {
    .Home_section.\#visualisation[base] > & {
        // Own layout
        margin-top: var(--space-6);
        grid-column: 1 / -1;
        grid-row: 3;

        @media ($bp1_true) {
            // Own layout
            margin-top: var(--space-8);
        }
    }
}

.VisuSlider_tabsContent {
    .Home_section.\#visualisation[base] > & {
        // Own layout
        margin-top: var(--space-6);
        grid-column: 1 / -1;
        grid-row: 4;

        @media ($bp1_true) {
            // Own layout
            margin-top: var(--space-8);
        }
    }
}

.Home_contactSectionTitle {
    &[base] {
        // Appearance
        font-size: var(--text-2xl);
        font-variation-settings: "wght" var(--font-weight-semibold);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.Home_contactText {
    &[base] {
        // Own layout
        grid-column: 2;
        grid-row: 2;

        // Inner layout
        display: grid;
        gap: var(--space-3);
    }
}

.Button_root.\#contact {
    .Home_section[base] > & {
        // Own layout
        grid-column: 2;
        grid-row: 3;
        margin-top: var(--space-7);
    }
}
