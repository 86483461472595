// states: :hover, :focus

.CustomLink_root {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Miscellaneous
        transition: all var(--ease-in) var(--duration-100);

        // Inner layout
        line-height: var(--leading-normal);

        &:hover {
            // Appearance
            color: var(--gray-900);
        }

        &:focus {
            // Appearance
            text-decoration: underline var(--gray-700);
        }
    }
}
