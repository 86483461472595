@font-face {
    font-family: "Jost";
    src: url("./../../assets/fonts/Jost-VariableFont_wght.ttf");
    font-display: swap;
}

:root {
    // Layout

    --space-1: 4px;
    --space-2: 8px;
    --space-3: 12px;
    --space-4: 16px;
    --space-5: 20px;
    --space-6: 24px;
    --space-7: 32px;
    --space-8: 40px;
    --space-9: 48px;
    --space-10: 64px;
    --space-11: 80px;
    --space-12: 96px;
    --space-13: 128px;
    --space-14: 160px;
    --space-15: 192px;
    --space-16: 224px;

    --thickness-sm: 1px;
    --thickness-md: 2px;
    --thickness-lg: 4px;
    --thickness-xl: 8px;

    // Radius

    --radius-full: 9999px;
    --radius-sm: 4px;
    --radius-md: 8px;
    --radius-lg: 12px;
    --radius-xl: 16px;

    // Colors

    --white: #fff;

    --gray-100: #f7fafc;
    --gray-200: #edf2f7;
    --gray-300: #e2e8f0;
    --gray-400: #cbd5e0;
    --gray-500: #a0aec0;
    --gray-600: #718096;
    --gray-700: #4a5568;
    --gray-800: #2d3748;
    --gray-900: #1a202c;

    --indigo-300: #a3bffa;
    --indigo-400: #7f9cf5;
    --indigo-500: #667eea;
    --indigo-600: #5a67d8;

    --red-300: #feb2b2;
    --red-400: #fc8181;
    --red-500: #f56565;
    --red-700: #c53030;

    --green-300: #9ae6b4;
    --teal-300: #81e6d9;
    --orange-300: #fbd38d;
    --blue-300: #90cdf4;

    // Effects

    --shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-md-inverted: 0px -1px 2px rgba(0, 0, 0, 0.06),
        0px -1px 3px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-lg-improved: 0px -1px 4px rgba(0, 0, 0, 0.04),
        0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.07);
    --shadow-lg-inverted: 0px -2px 4px rgba(0, 0, 0, 0.06),
        0px -2px 6px rgba(0, 0, 0, 0.1);
    --shadow-xl: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

    // Transitions

    --ease-linear: linear;
    --ease-in: cubic-bezier(0.4, 0, 1, 1);
    --ease-out: cubic-bezier(0, 0, 0.2, 1);
    --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);

    --duration-75: 75ms;
    --duration-100: 100ms;
    --duration-150: 150ms;
    --duration-200: 200ms;
    --duration-250: 250ms;
    --duration-300: 300ms;
    --duration-500: 500ms;
    --duration-700: 700ms;
    --duration-1000: 1000ms;

    // Text

    --font-sans-serif: "Jost", system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";

    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;
    --text-6xl: 4rem;

    --font-weight-hairline: 100;
    --font-weight-thin: 200;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
    --font-weight-black: 900;

    --leading-none: 1;
    --leading-tight: 1.25;
    --leading-snug: 1.375;
    --leading-normal: 1.5;
    --leading-relaxed: 1.625;
    --leading-loose: 2;
}
