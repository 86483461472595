// states: @media ($bp1_true|$bp1_false), tabsContentVisible:false|true

$bp1_true: "min-width: 1080px";
$bp1_false: "max-width: 1079px";

.VisuSlider_tabNavWrapper {
    &[base] {
        // Miscellaneous
        overflow-x: auto;
        overscroll-behavior: contain auto;
        scrollbar-width: none; // For Firefox

        // Inner layout
        padding: var(--space-2) 0;
        display: grid;
        grid-auto-flow: column;
        justify-items: center;
    }
}

// For Chromium/Webkit-based browsers
.VisuSlider_tabNavWrapper[base]::-webkit-scrollbar {
    // Own layout
    display: none;
}

// Hack to have space before and after inside the scroll container
.VisuSlider_tabNavWrapper[base]::before,
.VisuSlider_tabNavWrapper[base]::after {
    content: "";
    width: var(--space-6);
}

.VisuSlider_tabsContent {
    &[base] {
        // Inner layout
        // A. To prevent shadow cropping
        // B. To prevent vertical scrolling when items are hidden and translated on Y
        margin: calc(-1 * var(--space-3)) 0;
        display: grid;
        grid-auto-flow: column;

        // Miscellaneous
        overflow-x: auto;
        overscroll-behavior: contain auto;
        scroll-snap-type: x mandatory;
        // Fix Safari desktop only bug:
        // removes scroll-snap to prevent choppy scrolling
        // while updating the tab nav...
        @media (min-width: 800px) {
            @supports (-webkit-appearance: none) and (display: flow-root) {
                scroll-snap-type: none;
            }
        }
        scrollbar-width: none; // For Firefox

        // Inner layout
        // A. To prevent shadow cropping
        // B. To prevent vertical scrolling when items are hidden and translated on Y
        padding: var(--space-3) 0;
        display: flex;
        align-items: flex-start;
    }
}

// For Chromium/Webkit-based browsers
.VisuSlider_tabsContent[base]::-webkit-scrollbar {
    // Own layout
    display: none;
}

// Hack to have space before and after inside the scroll container
.VisuSlider_tabsContent[base]::before,
.VisuSlider_tabsContent[base]::after {
    content: "";

    // Own layout
    flex-shrink: 0;
    width: var(--space-6); // Fallback if max() is not supported
    width: calc((max(calc(100vw - 976px), 48px) / 2));
    height: 10px;
}

.VisuSlider_tabContent {
    &[base] {
        // Own layout
        --itemsGap: var(--space-5);
        margin-right: var(--itemsGap);
        width: auto;

        // Appearance
        border-radius: var(--radius-lg);
        box-shadow: var(--shadow-lg-improved);
        background-color: var(--white);

        // Miscellaneous
        transition: all var(--ease-in-out) var(--duration-500);

        // Inner layout
        display: grid;
        padding: var(--space-5);
        gap: var(--space-5);

        @media ($bp1_true) {
            // Own layout
            --itemsGap: var(--space-7);

            // Inner layout
            padding: var(--space-6) var(--space-7) var(--space-7);
            gap: var(--space-6);
        }

        &.§tabsContentVisible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§tabsContentVisible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }
    }
}

.VisuSlider_tabContent.\#2 {
    &[base] {
        // Miscellaneous
        transition-property: all;
        transition-delay: var(--duration-250);
        transition-duration: var(--duration-500);
        transition-timing-function: var(--ease-in-out);
    }
}

.VisuSlider_tabContent.\#last {
    &[base] {
        // Own layout
        margin-right: 0;

        // Miscellaneous
        transition-property: all;
        transition-delay: var(--duration-500);
        transition-duration: var(--duration-500);
        transition-timing-function: var(--ease-in-out);
    }
}

.VisuSlider_tabTitle {
    &[base] {
        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-semibold);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.VisuSlider_graphWrapper {
    &[base] {
        // Inner layout
        position: relative;
        scroll-snap-align: center;
    }
}

.VisuSlider_graph {
    &[base] {
        // Own layout
        max-width: 75vw;
        height: auto;

        // Miscellaneous
        user-select: none;
    }
}

.VisuSlider_graph.\#1 {
    &[base] {
        // Own layout
        width: 629px;
    }
}

.VisuSlider_graph.\#2 {
    &[base] {
        // Own layout
        width: 513px;
    }
}

.VisuSlider_graph.\#3 {
    &[base] {
        // Own layout
        width: 389px;
    }
}
