.CoverageCategory_root {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-9);
        justify-items: center;

        // Miscellaneous
        transition: all var(--ease-in-out) var(--duration-500);

        &.§visible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§visible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }
    }
}

.CoverageCategory_illustration {
    &[base] {
        // Own layout
        width: 100%;

        // Inner layout
        position: relative;
        padding-bottom: 64.33823529%;
    }
}

svg {
    .CoverageCategory_illustration[base] > & {
        // Own layout
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.CoverageCategory_categoryTitle {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}
