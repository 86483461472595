// variations: -fixedSize

.Icon_root {
    &[base] {
        // Inner layout
        display: flex;
        justify-content: center;
    }
}

svg {
    .Icon_root[base] > & {
        // Own layout
        width: 100%;
        height: 100%;
    }

    .Icon_root._fixedSize > & {
        // Own layout
        width: auto;
        height: auto;
    }
}

path {
    .Icon_root[base] & {
        // Appearance
        stroke: var(--stroke-color, #000);
    }
}
