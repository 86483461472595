// variations: nextToGray:true|false

.Divider_root {
    &[base] {
        // Own layout
        width: 100%;
        height: 0;

        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-200);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
    }

    &._nextToGray\:true {
        // Appearance
        border-bottom-color: var(--gray-300);
    }
}
