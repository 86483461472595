// states: @media ($bp1_true|$bp1_false)

$bp1_true: "min-width: 760px";
$bp1_false: "max-width: 759px";

.Contact_section {
    &[base] {
        // Inner layout
        display: grid;
        // prettier-ignore
        grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);
        // prettier-ignore
        grid-template-rows: var(--space-10) auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            // prettier-ignore
            grid-template-rows: var(--space-12) auto var(--space-12);
        }
    }
}

.Contact_section.\#form {
    &[base] {
        --menuHeight: var(--space-10);

        // Inner layout
        // prettier-ignore
        grid-template-rows: calc(var(--menuHeight) + var(--space-10)) auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            // prettier-ignore
            grid-template-rows: calc(var(--menuHeight) + var(--space-12)) auto var(--space-12);
        }
    }
}

.DotsPattern_root {
    .Contact_section[base] > & {
        // Inner layout
        position: relative;
        z-index: -1;
    }
}

.DotsPattern_root::before {
    .Contact_section[base] > & {
        content: "";

        // Own layout
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

.DotsPattern_root.\#1 {
    .Contact_section[base] > & {
        // Own layout
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        justify-self: start;
        height: 400px;
        width: 500px;
        max-width: 100vw;
    }
}

.DotsPattern_root.\#1::before {
    .Contact_section[base] > & {
        // Appearance
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.5) 30%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 1) 100%
        );

        @media ($bp1_true) {
            // Appearance
            background: linear-gradient(
                135deg,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0.66) 50%,
                rgba(255, 255, 255, 1) 100%
            );
        }
    }
}

.DotsPattern_root.\#2 {
    .Contact_section[base] > & {
        // Own layout
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        justify-self: end;
        align-self: end;
        height: 200px;
        width: 500px;
        max-width: 100vw;
    }
}

.DotsPattern_root.\#2::before {
    .Contact_section[base] > & {
        // Appearance
        background: linear-gradient(
            -25deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.6) 30%,
            rgba(255, 255, 255, 1) 100%
        );

        @media ($bp1_true) {
            // Appearance
            background: linear-gradient(
                -25deg,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0.66) 40%,
                rgba(255, 255, 255, 1) 100%
            );
        }
    }
}

.Contact_sectionContent {
    &[base] {
        // Own layout
        grid-column: 2;
        grid-row: 2;

        // Inner layout
        display: grid;
        gap: var(--space-9);
    }
}

.Contact_text {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-6);
        justify-items: center;
    }
}

.Contact_sectionTitle {
    &[base] {
        // Own layout
        max-width: 640px;

        // Appearance
        font-size: var(--text-4xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-snug);
    }
}

.Contact_sectionParagraph {
    &[base] {
        // Own layout
        max-width: 640px;

        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

// Necessary to use min() function in SASS...
@function css-function($function, $values...) {
    @return $function + unquote("(") + $values + unquote(")");
}
@function css-min($values...) {
    @return css-function(min, $values);
}

.Contact_form {
    &[base] {
        // Own layout
        justify-self: center;
        width: 100%;
        max-width: css-min(calc(640px + var(--space-7) * 2), 100%);

        // Appearance
        box-shadow: var(--shadow-lg-improved);
        border-radius: var(--radius-lg);
        background-color: var(--white);

        // Inner layout
        padding: var(--space-4);
        display: grid;
        grid-template-columns: repeat(
            auto-fill,
            minmax(calc(min(304px, 100%)), 1fr)
        );
        gap: var(--space-5) var(--space-7);
        align-items: start;

        @media ($bp1_true) {
            padding: var(--space-7);
        }
    }
}

.Contact_textInputWrapper {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-1);

        @media ($bp1_true) {
            // Own layout
            max-width: 472px;
        }
    }
}

.Contact_textInputWrapper.\#email {
    &[base] {
        // Own layout
        grid-column: 1 / -1;
    }
}

.Contact_textInputWrapper.\#company {
    &[base] {
        // Own layout
        grid-column: 1 / -1;
    }
}

.Contact_textInputWrapper.\#message {
    &[base] {
        // Own layout
        grid-column: 1 / -1;

        @media ($bp1_true) {
            // Own layout
            max-width: 100%;
        }
    }
}

.TextInput_field[base].\#message {
    .Contact_textInputWrapper[base] > & {
        // Own layout
        min-height: 230px;
    }
}

.Button_root._type\:text {
    .Contact_form[base] > & {
        // Own layout
        grid-column: 1 / -1;
        justify-self: center;
    }
}

.Contact_formErrorMessage {
    &[base] {
        // Own layout
        grid-column: 1 / -1;

        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--red-700);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.Contact_formSuccessMessage {
    &[base] {
        // Own layout
        grid-column: 1 / -1;

        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.Contact_section.\#info {
    &[base] {
        // Appearance
        background-color: var(--gray-100);

        // Inner layout
        // prettier-ignore
        grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 640px) minmax(var(--space-6), 1fr);
        justify-items: start;
    }
}

.Contact_infoSection {
    &[base] {
        // Own layout
        grid-column: 2;
        grid-row: 2;
        max-width: 472px;

        // Inner layout
        display: grid;
        gap: var(--space-4);
    }
}

.Contact_infoTitle {
    &[base] {
        // Appearance
        font-size: var(--text-2xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.Contact_infoWrapper {
    &[base] {
        // Inner layout
        display: grid;
        gap: var(--space-2);
    }
}

.Contact_info {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.Contact_strong {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}
