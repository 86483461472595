// variations: _inFooter, _style:filled|outlined, _type:text|icon, _size:small|big, _withChevron
// states: §overContent:true|false, :focus, :hover

.Button_root {
    &[base] {
        // Own layout
        display: inline-flex;

        // Appearance
        background-color: var(--indigo-500);
        border-radius: var(--radius-full);

        // Miscellaneous
        cursor: pointer;
        user-select: none;
        transition: all var(--ease-in) var(--duration-100);

        // Inner layout
        padding: var(--space-2) var(--space-4);
        align-items: center;

        &:hover {
            // Appearance
            background-color: var(--indigo-600);
        }

        &:focus {
            // Appearance
            box-shadow: 0 0 0 var(--thickness-md) var(--white),
                0 0 0 var(--thickness-lg) var(--indigo-500);
        }

        &.§overContent\:true {
            // Appearance
            background-color: var(--white);
        }

        &.§overContent\:true:focus {
            // Appearance
            box-shadow: 0 0 0 var(--thickness-md) var(--gray-500),
                0 0 0 var(--thickness-lg) var(--white);
        }

        &.§overContent\:true:hover {
            // Appearance
            background-color: rgba(255, 255, 255, 0.75);
        }
    }

    &._inFooter {
        // Appearance
        background-color: var(--gray-500);

        &:hover {
            // Appearance
            background-color: var(--gray-600);
        }

        &:focus {
            // Appearance
            box-shadow: 0 0 0 var(--thickness-md) var(--gray-200),
                0 0 0 var(--thickness-lg) var(--gray-500);
        }
    }

    &._type\:icon {
        // Own layout
        width: var(--space-8);
        height: var(--space-8);

        // Inner layout
        padding: 0;
        justify-content: center;
    }

    &._size\:small {
        // Inner layout
        padding: var(--space-1) var(--space-3);
    }

    &._size\:big {
        // Inner layout
        padding: var(--space-3) var(--space-6);
    }

    &._style\:outlined._size\:big {
        // Appearance
        border-style: solid;
        border-color: var(--white);
        background-color: transparent;

        // Inner layout
        border-width: var(--thickness-md);
        padding: calc(var(--space-3) - var(--thickness-md))
            calc(var(--space-6) - var(--thickness-md)); // In order to keep the same size as with _style:filled

        &:hover {
            background-color: var(--white);
        }

        &:focus {
            // Appearance
            box-shadow: 0 0 0 var(--thickness-md) var(--white);
        }
    }
}

// text
.Button_root {
    &[base] {
        // Appearance
        font-size: var(--text-base);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--white);

        // Inner layout
        line-height: var(--leading-normal);
    }

    &._size\:big {
        // Appearance
        font-size: var(--text-xl);
    }

    &.§overContent\:true {
        // Appearance
        color: var(--gray-900);
    }

    &._style\:outlined {
        // Appearance
        color: var(--white);

        &:hover {
            color: var(--gray-800);
        }
    }
}

.Icon_root.\#chevron {
    .Button_root[base] > & {
        // Style props
        --stroke-color: var(--white);

        // Own layout
        margin-left: var(--space-2);
        width: 6px;
        height: 10px;
    }

    .Button_root._size\:big > & {
        // Own layout
        margin-left: var(--space-3);
        --height: calc(10 * 20 / 16 * 1px);
        width: calc(6 * var(--height) / 10);
        height: var(--height);
    }
}

.Icon_root {
    .Button_iconWrapper._type\:icon > & {
        // Style props
        --stroke-color: var(--white);
    }
}

.Button_hiddenLabel {
    &[base] {
        // Visually hidden
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}
