.DotsPattern_root {
    &[base] {
        // Miscellaneous
        overflow: hidden;
    }
}

.DotsPattern_image {
    &[base] {
        // Miscellaneous
        user-select: none;
    }
}
