.TabNav_root {
    // Appearance
    box-shadow: var(--shadow-md);
    border-radius: var(--radius-full);
    background-color: var(--gray-100);

    // Inner layout
    display: grid;
}

.TabNav_list {
    // Own layout
    grid-column: 1;
    grid-row: 1;

    // Inner layout
    padding: var(--space-2);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: var(--space-2);
}

.TabNav_clippedList {
    // Own layout
    grid-column: 1;
    grid-row: 1;

    // Appearance
    background-color: var(--gray-700);
    --inset-vertical: var(--space-2);
    --inset-left: 0;
    --inset-right: 0;
    // prettier-ignore
    clip-path: inset(
        var(--inset-vertical)
        calc(var(--inset-right, 0) * 1px)
        var(--inset-vertical)
        calc(var(--inset-left, 0) * 1px)
        round 18px
    );

    // Miscellaneous
    transition: all var(--ease-in-out) var(--duration-300);

    // Inner layout
    padding: var(--space-2);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: var(--space-2);
}
