// states: @media ($bp1_true|$bp1_false), @media ($bp2_true|$bp2_false), firstSponsorVisible:false|true, primeSponsorVisible:false|true

$bp1_true: "min-width: 548px";
$bp1_false: "max-width: 547px";
$bp2_true: "min-width: 760px";
$bp2_false: "max-width: 759px";

.About_section {
    &[base] {
        // Inner layout
        padding: var(--space-10) 0;
        display: grid;
        // prettier-ignore
        grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 976px) minmax(var(--space-6), 1fr);

        @media ($bp2_true) {
            // Inner layout
            padding: var(--space-12) 0;
        }
    }
}

.About_section.\#company {
    &[base] {
        --menuHeight: var(--space-10);

        // Inner layout
        padding: 0;
        // prettier-ignore
        grid-template-columns: minmax(var(--space-6), 1fr) minmax(0, 640px) minmax(var(--space-6), 1fr);
        // prettier-ignore
        grid-template-rows: calc(var(--menuHeight) + var(--space-10)) auto var(--space-10);

        @media ($bp1_true) {
            // Inner layout
            // prettier-ignore
            grid-template-rows: calc(var(--menuHeight) + var(--space-12)) auto var(--space-12);
        }
    }
}

.DotsPattern_root {
    .About_section[base] > & {
        // Own layout
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        justify-self: end;
        height: 500px;
        width: 500px;
        max-width: 92vw;

        // Inner layout
        position: relative;
        z-index: -1;
    }
}

.DotsPattern_root::before {
    .About_section[base] > & {
        content: "";

        // Own layout
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        // Appearance
        background: linear-gradient(
            225deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.66) 20%,
            rgba(255, 255, 255, 1) 100%
        );

        @media ($bp2_true) {
            // Appearance
            background: linear-gradient(
                225deg,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0.4) 20%,
                rgba(255, 255, 255, 1) 80%,
                rgba(255, 255, 255, 1) 100%
            );
        }
    }
}

.About_section.\#methodologies {
    &[base] {
        // Appearance
        background-color: var(--gray-100);
    }
}

.About_grid {
    &[base] {
        // Own layout
        grid-column: 2;

        // Inner layout
        display: grid;
        grid-template-columns: repeat(
            auto-fit,
            minmax(calc(min(450px, 100%)), 1fr)
        );
        gap: var(--space-10);
        align-items: center;
        justify-items: center;
    }
}

.About_sectionMainInformation {
    &[base] {
        // Inner layout
        display: grid;
        grid-auto-flow: row;
        gap: var(--space-6);
    }
}

.About_sectionMainInformation.\#company {
    &[base] {
        // Own layout
        grid-column: 2;
        grid-row: 2;
    }
}

.About_sectionTitle {
    &[base] {
        // Appearance
        font-size: var(--text-4xl);
        font-variation-settings: "wght" var(--font-weight-bold);
        font-family: var(--font-sans-serif);
        color: var(--gray-800);

        // Inner layout
        line-height: var(--leading-snug);
    }
}

.About_sectionTitle.\#company {
    &[base] {
        // Inner layout
        text-align: center;
    }
}

.About_content {
    &[base] {
        // Inner layout
        display: grid;
        grid-auto-flow: row;
        gap: var(--space-8);
        justify-items: center;
    }
}

.About_text {
    &[base] {
        // Inner layout
        display: grid;
        grid-auto-flow: row;
        gap: var(--space-6);
    }
}

.About_sectionParagraph {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.About_strong {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.About_sectionParagraph.\#company {
    &[base] {
        // Inner layout
        text-align: center;
    }
}

.About_externalLink {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-700);

        // Inner layout
        line-height: var(--leading-normal);
    }
}

.About_logo {
    &[base] {
        // Own layout
        width: 66%;
        max-width: 240px;
        height: auto;

        // Miscellaneous
        user-select: none;
    }
}

.About_sponsors {
    &[base] {
        // Own layout
        max-width: 500px;

        // Appearance
        border-radius: var(--radius-lg);
        border-style: solid;
        border-color: var(--gray-400);

        // Inner layout
        border-width: var(--thickness-sm);
    }
}

.About_sponsorsHeader {
    &[base] {
        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-400);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
        padding: var(--space-4) 0;
    }
}

// text
.About_sponsorsHeader {
    &[base] {
        // Appearance
        font-size: var(--text-xl);
        font-variation-settings: "wght" var(--font-weight-medium);
        font-family: var(--font-sans-serif);
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.About_sponsorsGroups {
    &[base] {
        // Inner layout
        padding: var(--space-4);
        display: grid;
        gap: var(--space-4);

        @media ($bp1_true) {
            // Inner layout
            padding: var(--space-7);
            gap: var(--space-7);
        }
    }
}

.About_sponsorGroup {
    &[base] {
        // Appearance
        border-radius: var(--radius-lg);
        border-style: solid;
        border-color: var(--gray-300);

        // Appearance
        box-shadow: var(--shadow-lg-improved);
        background-color: var(--white);

        // Miscellaneous
        transition: all var(--ease-in-out) var(--duration-500);

        // Inner layout
        border-width: var(--thickness-sm);
    }
}

.About_sponsorGroup.\#first {
    &[base] {
        &.§firstSponsorVisible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§firstSponsorVisible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }
    }
}

.About_sponsorGroup.\#prime {
    &[base] {
        &.§primeSponsorVisible\:false {
            // Own layout
            transform: translateY(var(--space-3));

            // Appearance
            opacity: 0;
        }

        &.§primeSponsorVisible\:true {
            // Own layout
            transform: translateY(0);

            // Appearance
            opacity: 1;
        }
    }
}

.About_sponsorGroupHeader {
    &[base] {
        // Appearance
        border-bottom-style: solid;
        border-bottom-color: var(--gray-300);

        // Inner layout
        border-bottom-width: var(--thickness-sm);
        padding: var(--space-4) 0;
    }
}

// text
.About_sponsorGroupHeader {
    &[base] {
        // Appearance
        font-size: var(--text-lg);
        font-variation-settings: "wght" var(--font-weight-normal);
        font-family: var(--font-sans-serif);
        color: var(--gray-600);

        // Inner layout
        line-height: var(--leading-normal);
        text-align: center;
    }
}

.About_sponsorsLogos {
    &[base] {
        // Own layout
        padding: var(--space-4);
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--space-4);
        justify-items: center;
        align-items: center;

        @media ($bp1_true) {
            // Inner layout
            padding: var(--space-6);
            gap: var(--space-7);
        }
    }
}

.About_sponsorsLogos.\#prime {
    &[base] {
        // Own layout
        grid-template-columns: 1fr;
    }
}

.About_sponsorLogo {
    &[base] {
        // Own layout
        width: 40%;
        height: auto;

        // Miscellaneous
        user-select: none;
    }
}

.About_sponsorLogo.\#AFD {
    &[base] {
        // Own layout
        width: 45%;
    }
}

.About_sponsorLogo.\#banquePostale {
    &[base] {
        // Own layout
        width: 35%;
    }
}

.About_sponsorLogo.\#climateKIC {
    &[base] {
        // Own layout
        width: 92%;
    }
}

.About_sponsorLogo.\#meridiam {
    &[base] {
        // Own layout
        width: 90%;
    }
}

.About_sponsorLogo.\#generalli {
    &[base] {
        // Own layout
        width: 60%;
    }
}
