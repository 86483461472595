* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-width: 0;
    font-weight: normal;

    -webkit-tap-highlight-color: transparent; /* Removes the grey highlight on clickable elements on iOS */
    -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
    -ms-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in IE */
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Attribute used to hide only visually an element so that
// it is only present for screen-readers and robots
[data-visually-hidden] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

ul,
ol {
    list-style: none;
}

strong,
em {
    font-style: normal;
}

/* Correct the inability to style clickable types in iOS and Safari */
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    border: none;
    background-color: transparent;
}

/* Remove the inner border and padding in Firefox */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/* Remove the default vertical scrollbar in IE 10+ */
textarea {
    overflow: auto;
}

/* Correct the cursor style of increment and decrement buttons in Chrome */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/* Remove the inner padding in Chrome and Safari on macOS */
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/* Correct the inability to style clickable types in iOS and Safari */
::-webkit-file-upload-button {
    -webkit-appearance: button;
}

/* Remove browsers styling */
input[type="text"],
input[type="date"],
select,
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
}

a {
    text-decoration: none;
    outline: none;
}

button {
    outline: none;
}
